<template>
  <land-section
    id="home_hot"
    space="80"
  >
    <land-section-heading
      v-if="header.title"
      :title="header.title"
    >
      {{ header.subtitle }}
    </land-section-heading>
    <v-container
      style="max-width: 1200px;"
    >
      <v-row
        no-gutters
        justify="center"
      >
        <v-col cols="12">
          <land-gallery
            :items="items"
            :ratio="ratio"
            :titled="false"
            scaled
            back-color="grey lighten-5"
            @click="onItem"
          />
        </v-col>
      </v-row>
    </v-container>
    <div class="d-flex flex-column align-center">
      <v-btn
        v-if="button.text"
        depressed
        :color="button.backColor || 'primary'"
        :class="[button.foreColor ? `${button.foreColor}--text` : 'white--text', 'mt-5']"
        width="120"
        @click="onMore"
      >
        {{ button.text }}
      </v-btn>
    </div>
  </land-section>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'

  import mixPreset from '@/pages/mixins/mix.preset'
  const parts = mixPreset.Parts
  const names = mixPreset.Names
  const part = parts[names.IMAGE]

  export default {
    name: 'SectionHomeRecommends',
    props: {},
    data () {
      return {
        header: {
          title: '没灵感？来看编辑推荐！',
          subtitle: '20000多张照片随心预览'
        },
        params: null,
        items: [],
        type: part.type,
        ratio: part.ratio,
        button: {
          text: '更多推荐'
        }
      }
    },
    created () {
      this.getContents()
    },
    methods: {
      onItem (ev) {
        // console.log({ ev })
        const params = {
          contentId: ev.item.contentId,
          title: ev.item.title,
          type: ev.item.tytpe
        }

        api.page.navigate(this, 'hotDetail', params)
      },
      onMore () {
        api.page.navigate(this, 'hot')
      },
      configItems () {
        let enough = false
        this.items = []
        for (const i in this.params.items) {
          const src = this.params.items[i] || {}
          const imgs = src.thumbs || []
          for (const j in imgs) {
            const cover = imgs[j] || ''
            if (cover) {
              const dst = {
                key: `img-${i}-${j}`,
                contentId: src.contentId,
                categoryId: src.categoryId,
                circleId: src.circleId,
                title: src.title,
                cover,
                type: src.type,
                likesNum: src.likesNum,
                collectNum: src.collectNum,
                hitNum: src.hitNum,
                forwardedNum: src.forwardedNum,
                commentNum: src.commentNum
              }
              this.items.push(dst)
            }

            if (this.items.length >= 6) {
              enough = true
              break
            }
          }

          if (enough) {
            break
          }
        }
      },
      getContents () {
        const me = this
        const executed = function (res) {
          if (res.status) {
            me.configItems()
          }
        }

        this.params = web.content.getParams({
          type: me.type,
          state: web.comm.States.PUBLISH,
          accessScope: web.comm.Scopes.PUBLIC,
          pageSize: '10',
          executed
        })

        api.httpx.getItems(this.params)
      }
    }
  }
</script>
